@import url(https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Lato);
@font-face {
    font-family: 'Salesforce';
    src: local('fonts'), url(/static/media/SalesforceSans-Regular.6c9ddaa8.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Bold';
    font-weight: 900;
    src: local('fonts'), url(/static/media/SalesforceSans-Bold.bab6f1ee.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Thin';
    font-weight: 900;
    src: local('fonts'), url(/static/media/SalesforceSans-Thin.b4b52fea.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Light';
    font-weight: 900;
    src: local('fonts'), url(/static/media/SalesforceSans-Light.76d3c842.ttf) format('truetype');
    font-display: swap;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Salesforce';
}

.react-switch-docusaurus{
    z-index: 1 !important;
    position: fixed !important;
    bottom: 30px !important;
    left: 15px !important;
}

@media only screen and (max-width: 700px) {
  .snacks{
    font-size: 2.5vw !important;
  } 
  .react-switch-docusaurus{
    /* display : none !important; */
    bottom: 70px !important;
  }
}
