@import 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css?family=Lato');

@font-face {
    font-family: 'Salesforce';
    src: local('fonts'), url(./fonts/SalesforceSans-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Bold';
    font-weight: 900;
    src: local('fonts'), url(./fonts/SalesforceSans-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Thin';
    font-weight: 900;
    src: local('fonts'), url(./fonts/SalesforceSans-Thin.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Salesforce Light';
    font-weight: 900;
    src: local('fonts'), url(./fonts/SalesforceSans-Light.ttf) format('truetype');
    font-display: swap;
}