body {
  margin: 0;
  padding: 0;
  font-family: 'Salesforce';
}

.react-switch-docusaurus{
    z-index: 1 !important;
    position: fixed !important;
    bottom: 30px !important;
    left: 15px !important;
}

@media only screen and (max-width: 700px) {
  .snacks{
    font-size: 2.5vw !important;
  } 
  .react-switch-docusaurus{
    /* display : none !important; */
    bottom: 70px !important;
  }
}